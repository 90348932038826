// Layout.js
import React from "react";
import { Outlet } from "react-router-dom";
import Header from "../Header/Header";
import Bottom from "../LandingPages/Bottom";

const Layout = () => (
  <div>
    <Header />
    <main>
      <Outlet />
    </main>
    <Bottom />
  </div>
);

export default Layout;
